<div class="my-foreign-currency" *ngIf="!reload && (pocketsList != null || summary != null) ">
    <div class="content">
        <div class="box-wrapper">
            <div class="main-title">
                <h2>ארנקי המט"ח שלך</h2>
                <a href="foreign-currency-pocket/personal/buy-coin" title="" class="default-link target-link-bold" *ngIf="canOpenNewPocket">פתיחת ארנק חדש</a>
            </div>

            <div class="my-foreign-wrapper" *ngIf="(pocketsList != null && pocketsList.length > 0)">
                <ngx-slick-carousel [ngClass]="{'one-fcp-card' : onePocket}" [config]="slideConfig" class="slider foregin-slider" *appIfStable>
                    <div *ngFor="let pocket of pocketsList" ngxSlickItem class="my-foreign-box " [ngClass]="{'not-active-box':isPocketActive(pocket), 'one-box': onePocket}">
                        <img src="{{pocket.CardInfo?.CardImage}}" alt="" class="card-img" />

                        <span class="card-num">{{pocket.CardInfo?.CardName}} {{pocket.CardInfo?.Last4Digits}}</span>
                        <h3>
                            <span>יתרה נכון להיום</span>
                            <div maxTooltip="היתרה כוללת גם עסקאות שאושרו וטרם נקלטו" class="tooltip1"></div>


                        </h3>
                        <div class="actions-details">
                            <span *ngFor="let c of pocket.Currencies" class="sum" [ngClass]="{'not-active': !c.IsActive}">
                                <span class="ltr-sum">
                                    {{c.Balance | maxCurrency: c.CurrencyId}}
                                </span> <span *ngIf="!c.IsActive" class="not-active-text">&nbsp; לא פעיל</span>
                            </span>
                        </div>
                        <a (click)="navigateAction(pocket)" title="" class="default-link target-link-bold info-action">לפירוט נוסף</a>
                        <div class="not-active-stamp">
                            <span>לא פעיל</span>
                        </div>
                    </div>
                </ngx-slick-carousel>

            </div>

            <div class="my-foreign-wrapper" *ngIf="summary != null">
                <ngx-slick-carousel [ngClass]="{'one-fcp-card' : onePocket}" [config]="slideConfig" class="slider foregin-slider" *appIfStable>
                    <div ngxSlickItem class="my-foreign-box" [ngClass]="{'one-box' : onePocket}">
                        <h3>יתרת מט״ח</h3>
                        <span class="card-num">ב {{summary.CardsAmount}} כרטיסי אשראי</span>
                        <div class="actions-details">
                            <span class="sum"> ארנקי מט"ח{{summary.PocketsAmount}}</span>
                            <span class="currencies"> {{getCurrencies()}}</span>
                            <span class="currencies">{{summary.EmptyPocketsAmount}} ארנקים ללא יתרה</span>
                        </div>
                        <a (click)="toDashboard()"  title="" class="default-link target-link-bold">לפרטים נוספים ומילוי הארנק</a>
                    </div>

                </ngx-slick-carousel>


            </div>

        </div>
    </div>
</div>





<!--<div class="my-foreign-wrapper" *ngIf="myCardsShowLoaderBlink">
    <div class="expected-obligation expected-obligation-template card-box myCardsShowLoaderBlink"></div>
    <div class="expected-obligation expected-obligation-template card-box myCardsShowLoaderBlink"></div>

</div>-->
