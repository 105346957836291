import { HttpClient } from '@angular/common/http';
import { AppConfigsService } from '../../../config/app-configs.service';
import {
    IResponse,
    HttpService,
    IHttpRequestOptions,
    IResponseLower
} from '../../../shared/cardholders-core/services/http-service.service';
import { IHomePageUserData } from '../models/homepage-user-data';
import { IHomePageRecommendationsDataResult, IHomePageRecommendationsResult } from '../models/homepage-recommendation';
import { Injectable } from '@angular/core';
import { UiHelperService } from '../../../shared/cardholders-core/services/ui-helper.service';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { ILoginInfo } from '../models/login-info';
import { ICardPointsData, ICardPointsResult } from '../models/homepage-points-data';
import { forkJoin, Subject } from 'rxjs';
import { IProductsForYouResult } from '../models/products-for-you';
import { AuthService, Roles } from '../../../shared/cardholders-core/services/auth.service';
import { IMonthlyBillingLayoutHomePage } from '../models/monthly-billing-layout-home-page.interface';
import { IAigSsoResult, IInsuranceDetails } from '../models/homepage-insurance-details';
import { IBogResult } from '../models/homepage-bog-wallet';
import { IForeignCurrencyPockets } from "../models/homepage-forigen-currency";
import { ImyMaxCard } from '../../max-account/interfaces/api-calls.interfaces';
import { IHomepageContent } from '../../new-homepage/interfaces/homepage-content.interface';
import { Router } from '@angular/router';

@Injectable()
export class HomepageService {
    productsChanged = new Subject<IResponse<IProductsForYouResult>>();
    pointsRecommendationsChanged = new Subject<IHomePageRecommendationsDataResult>();
    insuranceRecommendationsChanged = new Subject<IHomePageRecommendationsResult[]>();
    savingRecommendationsChanged = new Subject<IHomePageRecommendationsResult[]>();
    mymaxRecommendationsChanged = new BehaviorSubject<IHomePageRecommendationsResult[]>(null);
    insuranceRecommendation = undefined;
    savingRecommendation = undefined;
    homepageContent: IHomepageContent;

    private benefits: any;
    private benefitsEligibility: any;
    private cardCategories: any;
    private marketingProducts: any;
    private HomepageContentResult: any;
    public isMaxAccountExists: boolean;

    constructor(
        private http$: HttpClient,
        public appConfigsService: AppConfigsService,
        private uiHelperService: UiHelperService,
        private httpSvc: HttpService,
        private authSrv: AuthService,
        private router:  Router
    ) {}

    canLoadNewHomepage(): boolean {
        if (!this.appConfigsService.appConfigs.FLAG_ISEnableNewHomepage) {
            this.router.navigate(['/']);
            return false;
        }
        return true;
    }

    navigate(url: string): void {
        if (url.startsWith('/')) {
            this.router.navigateByUrl(url);
        } else if (url.startsWith(this.appConfigsService.appConfigs.baseUiRoot)) {
            this.router.navigateByUrl(url.slice(this.appConfigsService.appConfigs.baseUiRoot.length));
        } else {
            window.location.href = url;
        }
    }

    isDisplayNewsletterReg(): Observable<any> {
        const options: IHttpRequestOptions = {
            defaultApiFailureBehavior: false
        };
        return this.httpSvc.get(`${this.appConfigsService.appConfigs.apiUrl}/registered/newsletter`, null, options);
    }

    getNewsletterData(): Observable<any> {
        const url = `${this.appConfigsService.appConfigs.apiUrl}/newsletterdata`;
        return this.httpSvc.getWithSsrCache(url, 'homepageNewsletter');
    }

    loadBenefits = () => {
        return new Promise((resolve, reject) => {
            this.getBenefitsFromServer().subscribe(() => {
                switch (this.benefits.ReturnCode) {
                    case 0:
                        if (this.benefits.Result) {
                            resolve(this.benefits.Result);
                        } else {
                            resolve(undefined);
                        }
                        break;
                    case 9:
                        resolve(undefined);
                        break;
                    default:
                        resolve(undefined);
                        break;
                }
            });
        });
    }

    loadBenefitsEligibility = () => {
        return new Promise((resolve, reject) => {
            this.getBenefitsEligibilityFromServer().subscribe(() => {
                switch (this.benefitsEligibility.ReturnCode) {
                    case 0:
                        if (this.benefitsEligibility.Result) {
                            resolve(this.benefitsEligibility.Result);
                        } else {
                            resolve(undefined);
                        }
                        break;
                    case 9:
                        resolve(undefined);
                        break;
                    default:
                        resolve(undefined);
                        break;
                }
            });
        });
    }

    loadCardCategories = () => {
        return new Promise((resolve, reject) => {
            this.getCardCategoriesFromServer().subscribe(() => {
                switch (this.cardCategories.ReturnCode) {
                    case 0:
                        if (this.cardCategories.Result) {
                            const filteredSortedCardCategories = this.cardCategories.Result.CardCategoryResult.filter(
                                cat => cat.HomePageData.IsDisplayInHomepage
                            ).sort((cat1, cat2) => cat1.HomePageData.Order - cat2.HomePageData.Order);
                            this.cardCategories.Result.CardCategoryResult = filteredSortedCardCategories;
                            resolve(this.cardCategories.Result);
                        } else {
                            resolve(undefined);
                        }
                        break;
                    case 9:
                        resolve(undefined);
                        break;
                    default:
                        resolve(undefined);
                        break;
                }
            });
        });
    }

    loadMarketingProducts = () => {
        return new Promise((resolve, reject) => {
            this.getMarketingProductsFromServer().subscribe(() => {
                switch (this.marketingProducts.ReturnCode) {
                    case 0:
                        if (this.marketingProducts.Result) {
                            resolve(this.marketingProducts.Result);
                        } else {
                            resolve(undefined);
                        }
                        break;
                    case 9:
                        resolve(undefined);
                        break;
                    default:
                        resolve(undefined);
                        break;
                }
            });
        });
    }

    loadHomepageContent = () => {
        return new Promise((resolve, reject) => {
            this.getHomepageContentFromServer().subscribe(() => {
                switch (this.HomepageContentResult.returnCode) {
                    case 0:
                        if (this.HomepageContentResult.result) {
                            resolve(this.HomepageContentResult.result);
                        } else {
                            resolve(undefined);
                        }
                        break;
                    case 9:
                        resolve(undefined);
                        break;
                    default:
                        resolve(undefined);
                        break;
                }
            });
        });
    }

    getHomePageData(): Observable<IResponse<IHomePageUserData>> {
        let skipCache = '';
        if (this.uiHelperService.isBrowser && localStorage.getItem('skip-cache') === 'active') {
            skipCache = `?skipeCache=${Math.floor(Math.random() * 100)}`;
        }
        if (this.uiHelperService.isBrowser && localStorage.getItem('skip-cache') === 'toRemove') {
            skipCache = `?skipeCache=${Math.floor(Math.random() * 100)}`;
            localStorage.removeItem('skip-cache');
        }
        return this.httpSvc.get(`${this.appConfigsService.appConfigs.apiUrl}/registered/getHomePageData${skipCache}`);
    }

    getLoginInfo(): Observable<IResponse<ILoginInfo>> {
        const url = `${this.appConfigsService.appConfigs.apiUrl}/registered/logininfo`;
        return this.httpSvc.get(url);
    }

    getNotifications(): Observable<IResponse<any>> {
        const options: IHttpRequestOptions = {
            defaultApiFailureBehavior: false
        };
        return this.httpSvc.get(
            `${this.appConfigsService.appConfigs.apiUrl}/registered/getNoticesForUser`,
            null,
            options
        );
    }

    removeNotification(id: number): Observable<any> {
        return this.httpSvc.get(`${this.appConfigsService.appConfigs.apiUrl}/registered/removeNotification/?id=${id}`);
    }

    getMyCardsRecommendations()
    : Observable<IResponse<any>> {
        return this.httpSvc.get(`${this.appConfigsService.appConfigs.apiUrl}/registered/getMyCardsRecommendations`);
    }

    getMyPointsRecommendations(): Observable<IResponse<number[]>> {
        return this.httpSvc.get(`${this.appConfigsService.appConfigs.apiUrl}/registered/getMyPointsRecommendations`);
    }

    getProductsForYou(): Observable<IResponse<IProductsForYouResult>> {
        return this.httpSvc.get(`${this.appConfigsService.appConfigs.apiUrl}/registered/productsForYou`);
    }

    getCardPointsData(): Observable<IResponse<ICardPointsResult>> {
        return this.httpSvc.get(`${this.appConfigsService.appConfigs.apiUrl}/registered/getCardPointsData`);
    }

    getBogWalletsData(): Observable<IResponse<IBogResult>> {
        return this.httpSvc.get(`${this.appConfigsService.appConfigs.apiUrl}/registered/getBogWalletsData`);
    }
    bogSSO(): Observable<IResponse<string>> {
        return this.httpSvc.get(`${this.appConfigsService.appConfigs.apiUrl}/registered/getSSOData`);
    }

    getRecommendationsFromServer(): Observable<IResponse<IHomePageRecommendationsDataResult>> {
        const url = `${this.appConfigsService.appConfigs.apiUrl}/registered/getRecommendations`;
        const options: IHttpRequestOptions = {
            defaultApiFailureBehavior: false
        };
        return this.httpSvc.getWithSsrCache(url, 'homepageRecommendations', null, options);
    }

    getInsuranceRecommendations(): Observable<IResponse<IHomePageRecommendationsDataResult>> {
        this.insuranceRecommendation = undefined;
        return this.httpSvc.get(`${this.appConfigsService.appConfigs.apiUrl}/registered/getInsuranceRecommendations`);
    }
    getSavingRecommendations(): Observable<IResponse<IHomePageRecommendationsDataResult>> {
        this.savingRecommendation = undefined;
        return this.httpSvc.get(`${this.appConfigsService.appConfigs.apiUrl}/registered/getSavingRecommendations`);
    }
    getMyMaxRecommendations(): Observable<IResponse<IHomePageRecommendationsDataResult>> {
        this.savingRecommendation = undefined;
        return this.httpSvc.get(`${this.appConfigsService.appConfigs.apiUrl}/registered/getMyMaxRecommendations`);
    }

    getRecommendationDataResult(): Observable<IHomePageRecommendationsDataResult> {
        const defaultRecommedationsIdsToShowForCards: number[] = [4, 5];
        const defaultRecommedationsIdsToShowForPoints: number[] = [9];
        return new Observable(observer => {
            forkJoin([
                this.getRecommendationsFromServer(),
                this.getMyCardsRecommendations(),
                this.getProductsForYou(),
                this.getMyPointsRecommendations(),
                this.getInsuranceRecommendations(),
                this.getSavingRecommendations(),
                this.getMyMaxRecommendations()
            ]).subscribe(results => {
                // results[0] is our recommendations [] data from umbraco
                // results[1] is our cards recommendations [] Ids from AS
                // results[2] is products for you
                // results[3] is our points recommendations [] Ids from AS
                // results[4] is our insurance recommendations [] from umbraco - filtered and sorted
                // results[5] is our saving recommendations []
                // results[6] is our my-max recommendations [] from umbraco - filtered and sorted
                this.updateRecommendationResults(results[1], results[2]);
                observer.next({
                    HomePageRecommendationsResults:
                    (this.appConfigsService.appConfigs.FLAG_ISEnableDashboardMyCardsRecommendationsFromPersonalMessages)
                     ? this.mergeUmbracoAndAsPersonalMessagesResults(
                        results[0],
                        results[1]
                    ) : this.mergeUmbracoAndAsResults(
                        results[0],
                        results[1],
                        defaultRecommedationsIdsToShowForCards
                    )
                    ,
                    BackgroundRecResult: results[0].Result.BackgroundRecResult
                });
                this.pointsRecommendationsChanged.next({
                    HomePageRecommendationsResults: this.mergeUmbracoAndAsResults(
                        results[0],
                        results[3],
                        defaultRecommedationsIdsToShowForPoints
                    ),
                    BackgroundRecResult: results[0].Result.BackgroundRecResult
                });

                this.insuranceRecommendation = results[4]?.Result?.HomePageRecommendationsResults;
                this.savingRecommendation = results[5]?.Result?.HomePageRecommendationsResults;

               setTimeout(() => {
                    this.insuranceRecommendationsChanged.next(results[4]?.Result?.HomePageRecommendationsResults);
                    this.savingRecommendationsChanged.next(results[5]?.Result?.HomePageRecommendationsResults);
                    this.mymaxRecommendationsChanged.next(results[6]?.Result?.HomePageRecommendationsResults);
              }, 0);
                if (this.authSrv.isUserInRole(Roles.GALI)) {
                    this.updateIsFirstPageAfterSSO().subscribe();
                }
            });
        });
    }

    mergeUmbracoAndAsResults(
        recommendation: IResponse<IHomePageRecommendationsDataResult>,
        ids: IResponse<number[]>,
        defaultRecommedationsIds: number[]
    ): IHomePageRecommendationsResult[] {
        if (recommendation.ReturnCode === 0 && ids.ReturnCode === 0) {
            return ids.Result.map(rec =>
                recommendation.Result.HomePageRecommendationsResults.find(hm => parseInt(hm.RecommendationId, 10) === rec)
            ).filter(e => e !== undefined);
        } else if (recommendation.ReturnCode === 0) {
            return recommendation.Result.HomePageRecommendationsResults.filter(rec =>
                defaultRecommedationsIds.includes(parseInt(rec.RecommendationId, 10))
            );
        } else {
            return null;
        }
    }
    mergeUmbracoAndAsPersonalMessagesResults(
        recommendation: IResponse<IHomePageRecommendationsDataResult>,
        ids: IResponse<{RecommendationId: string, RecPriority: number}[]>
    ): IHomePageRecommendationsResult[] {
        if (recommendation.ReturnCode === 0 && ids.ReturnCode === 0) {
            const result = ids.Result.filter( (id, index, self) => index ===
            self.findIndex((o) => o.RecommendationId === id.RecommendationId ))
            .sort((a, b) => a.RecPriority < b.RecPriority ? 1 : -1)
            .map(rec => recommendation.Result.HomePageRecommendationsResults
                .find(umbracoRec => umbracoRec.ExternalRecommendationId === rec.RecommendationId));
            return result;
        }
    }

    updateRecommendationResults(ids: IResponse<number[]>, products: IResponse<IProductsForYouResult>): void {
        if (products.ReturnCode === 0) {
            this.productsChanged.next(products);
        } else {
            this.productsChanged.next(null);
        }
    }

    updateInstantLoanData(loanAmount: number, loanPayments: number): Observable<IResponse<any>> {
        return this.httpSvc.post(`${this.appConfigsService.appConfigs.apiUrl}/registered/updateInstantLoanData`, {
            amount: loanAmount,
            payments: loanPayments
        });
    }

    updateIsFirstPageAfterSSO(): Observable<IResponse<any>> {
        return this.httpSvc.post(
            `${this.appConfigsService.appConfigs.apiUrl}/registered/updateIsFirstPageAfterSSO`,
            null
        );
    }

    getMonthlyBillingLayoutHomePage(): Observable<IResponseLower<IMonthlyBillingLayoutHomePage>> {
        return this.httpSvc.getLower(
            `${this.appConfigsService.appConfigs.apiUrl}/registered/monthlyBillingLayout/getHomePageData`
        );
    }

    getAigSso(phoneNumber: string): Observable<IResponseLower<IAigSsoResult>> {
        return this.httpSvc.getLower(
            `${this.appConfigsService.appConfigs.apiUrl}/registered/myInsurance/getAigSso?phoneNumber=${phoneNumber}`
        );
    }

    checkAgreementsStatus(typeCode: number) {
        return this.httpSvc.postLower(`${this.appConfigsService.appConfigs.apiUrl}/registered/agreements/checkStatus`, {
            typeCode: typeCode
        });
    }

    private getBenefitsFromServer = () => {
        const url = `${this.appConfigsService.appConfigs.apiUrl}/contents/HomepageBenefits`;
        return this.httpSvc.getWithSsrCache(url, 'homepageBenefits').pipe(tap(res => (this.benefits = res)));
    }

    private getBenefitsEligibilityFromServer = () => {
        const url = `${this.appConfigsService.appConfigs.apiUrl}/registered/benefitsEligibility`;
        return this.httpSvc
            .getWithSsrCache(url, 'homepageBenefitsEligibility')
            .pipe(tap(res => (this.benefitsEligibility = res)));
    }

    private getCardCategoriesFromServer = () => {
        const url = `${this.appConfigsService.appConfigs.apiUrl}/HomepageCards`;
        return this.httpSvc.getWithSsrCache(url, 'homepageCards').pipe(tap(res => (this.cardCategories = res)));
    }

    private getMarketingProductsFromServer = () => {
        const url = `${this.appConfigsService.appConfigs.apiUrl}/MarketingProducts`;
        return this.httpSvc.getWithSsrCache(url, 'marketingProducts').pipe(tap(res => (this.marketingProducts = res)));
    }

    private getHomepageContentFromServer = () => {
        const url = `${this.appConfigsService.appConfigs.apiUrl}/HomepageContent`;
        return this.httpSvc.getWithSsrCache(url, 'newHomepageContent').pipe(tap(res => (this.HomepageContentResult = res)));
    }


    getForeignCurrencyPockets(): Observable<IResponse<IForeignCurrencyPockets>> {
        return this.httpSvc.get(`${this.appConfigsService.appConfigs.apiUrl}/registered/getForeignCurrencyPockets`);
    }
}
