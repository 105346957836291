<div class="more-actions-in-max">
    <h2>{{ content?.title }}</h2>
    <div class="carusel-wrapper" (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)" (mouseup)="onMouseUp($event)" (mouseleave)="onMouseUp($event)">
        <ngx-slick-carousel [config]="slideConfig" dir="rtl" class="slider max-actions-carusel" (scroll)="onScroll()">
            <ng-container *ngFor="let item of content?.newsList">
                <div class="action-box" [ngClass]="{'only-text-box': getType(item) === 'bullets', 'only-title-text': getType(item) === 'text', 'disabled': isDragging}" ngxSlickItem>
                    <a *ngIf="getType(item) === 'image' && item.ctaLink" appLink [anchorLink]="item.ctaLink?.url"[isPreventDefaultForRouterLink]="true" [href]="item.ctaLink?.url" (click)="onCTAClick(item)">
                        <img [src]="item.image" [alt]="item.title" class="info-pic" />
                    </a>
                    <img *ngIf="getType(item) === 'image' && !item.ctaLink" [src]="item.image" [alt]="item.title" class="info-pic" />
                    <div class="action-info">
                        <a *ngIf="item.ctaLink" appLink [anchorLink]="item.ctaLink?.url"[isPreventDefaultForRouterLink]="true" [href]="item.ctaLink?.url" (click)="onCTAClick(item)"><h3>{{ item.title }}</h3></a>
                        <a *ngIf="!item.ctaLink"><h3>{{ item.title }}</h3></a>

                        <ng-container [ngSwitch]="getType(item)">
                            <p *ngSwitchCase="'image'" class="info-text">{{ item.textWithPicture }}</p>

                            <ul *ngSwitchCase="'bullets'" class="info-list">
                                <li *ngFor="let bullet of item.bulletsList; let i = index">
                                    <span class="number">{{ '0' + (i + 1) }}</span>
                                    {{ bullet }}
                                </li>
                            </ul>

                            <p *ngSwitchCase="'text'" class="article-text">{{ item.textWithoutPicture }}</p>
                        </ng-container>
                    </div>
                    <a *ngIf="item.ctaLink" class="action-link" title="" appLink [anchorLink]="item.ctaLink.url"[isPreventDefaultForRouterLink]="true" [href]="item.ctaLink.url" (click)="onCTAClick(item)">{{ item.ctaLink.text }}</a>
                    <span *ngIf="item.tag" class="tag">{{ item.tag }}</span>
                </div>
            </ng-container>
        </ngx-slick-carousel>
    </div>
</div>