import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { CardholdersCoreModule } from '../../shared/cardholders-core/cardholders-core.module';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { HomepageService } from './services/homepage.service';
import { BenefitsComponent } from './components/benefits/benefits.component';
import { ProductsComponent } from './components/products/products.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ChooseCardComponent } from './components/choose-card/choose-card.component';
import { MyCardsComponent } from './components/my-cards/my-cards.component';
import { CycleSummaryFilter, SummaryFilter } from './filter/filter.pipe';
import { NegativePricePipe } from './pipes/negative-price.pipe';
import { HelloComponent } from './components/hello/hello.component';
import { MyPointsComponent } from './components/my-points/my-points.component';
import { RecommendationBoxComponent } from './components/recommendation-box/recommendation-box.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { PersonalHomeComponent } from './components/personal-home/personal-home.component';
import { WelcomeHomeComponent } from './components/welcome-home/welcome-home.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RegisteredProductsComponent } from './components/registered-products/registered-products.component';
import { RegProductBoxComponent } from './components/registered-products/reg-product-box/reg-product-box.component';
import { LoanProductBoxComponent } from './components/registered-products/reg-product-box/loan-product-box/loan-product-box.component';
import { MyInsuranceComponent } from './components/policy/my-insurance/my-insurance.component';
import { PolicyComponent } from './components/policy/policy.component';
import { MarketingAgreementPopupModule } from '../marketing-agreement-popup/marketing-agreement-popup.module';
import { LottieModule } from '../../shared/modules/lottie/lottie.module';
import { CustomerIdentificationService } from '../customer-identification/services/customer-identification.service';
import { SavingComponent } from './components/policy/saving/saving.component';
import { InsuranceService } from './services/insurance.service';
import { MyBitsOfGoldComponent } from './components/my-bits-of-gold/my-bits-of-gold.component';
import { MaxAccountComponent } from './components/max-account/max-account.component';
import { SidePopupComponent } from './components/policy/side-popup/side-popup.component';
import { RecaptchaModule, RECAPTCHA_LANGUAGE, RecaptchaFormsModule } from 'ng-recaptcha';
import { MyForeignCurrencyComponent } from './components/my-foreign-currency/my-foreign-currency.component';
import { MaxCurrencyPipe } from "./pipes/max-currency.pipe";
import { MyMaxInfoComponent } from './components/my-max-info/my-max-info.component';

@NgModule({
    imports: [
        CommonModule,
        CardholdersCoreModule,
        SlickCarouselModule,
        FormsModule,
        RouterModule,
        MarketingAgreementPopupModule,
        LottieModule,
        RecaptchaFormsModule,
        RecaptchaModule
    ],
    declarations: [
        NewsletterComponent,
        PolicyComponent,
        BenefitsComponent,
        ChooseCardComponent,
        MyCardsComponent,
        CycleSummaryFilter,
        NegativePricePipe,
        SummaryFilter,
        ProductsComponent,
        HelloComponent,
        MyPointsComponent,
        RecommendationBoxComponent,
        NotificationsComponent,
        PersonalHomeComponent,
        WelcomeHomeComponent,
        RegisteredProductsComponent,
        RegProductBoxComponent,
        LoanProductBoxComponent,
        MyInsuranceComponent,
        SavingComponent,
        SidePopupComponent,
        MyBitsOfGoldComponent,
        MaxAccountComponent,
        MyForeignCurrencyComponent,
        MaxCurrencyPipe,
        MyMaxInfoComponent
    ],
    providers: [
        CustomerIdentificationService,
        HomepageService,
        DecimalPipe,
        InsuranceService,
        {
            provide: RECAPTCHA_LANGUAGE,
            useValue: 'iw' // use Hebrew language
        }
    ]
})
export class HomepageModule {}
