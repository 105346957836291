import { EventEmitter, Injectable } from '@angular/core';
import { HttpService, IResponseLower } from 'core-max-lib';
import { Observable } from 'rxjs/observable';
import { tap } from 'rxjs/operators';
import { AppConfigsService } from '../../../config/app-configs.service';
import { IModalConfig } from '../../../shared/modules/bs-modal/interfaces/modal-config.interface';
import { AlertStatusPopupComponent } from '../dashboard/dashboard-header/alert-status-popup/alert-status-popup.component';
import {
    GetPaymentsFetchDataResponse,
    ICancelAccountCrmLeadResponse,
    ICreatePaymentUrlAndSendInput,
    ICreatePaymentUrlAndSendResponse,
    IGetMaxAccountDashboardContent,
    IGetMaxAccountNewCreditByTranData,
    IGetMaxAccountNewCreditByTranRequest,
    IGetMaxAccountPaymentRequestDetails,
    IGetMaxAccountPaymentRequestDetailsInput,
    IGetMaxAccountPaymentsDetails,
    IGetPaymentClearingUrlPayload,
    IGetPaymentClearingUrlResponse,
    IMaxAccountMessage,
    IMaxAccountRecommendationsData,
    IMerApprovalsNoTrans,
    IValidatePaymentResponse,
    IWithdrawalFromMaxAccountInput,
    IWithdrawalFromMaxAccountSuccessPageData,
    MaxAccountDetailsResponse,
    MaxAccountMassageTypes,
    MAX_ACCOUNT_MASSAGE_PAGE_STATES,
    SortedTransaction
} from '../interfaces/api-calls.interfaces';

const API_ROUTE_PREFIX = 'registered/maxAccount';
const API_ENDPOINTS = {
    GetPaymentClearingUrl: 'getPaymentClearingUrl',
    GetMaxAccountPaymentsDetails: 'getMaxAccountPaymentsDetails',
    GetMaxAccountDashboardContent: 'getMaxAccountDashboardContent',
    ValidatePayment: 'validatePayment',
    CreatePaymentUrlAndSend: 'createPaymentUrlAndSend',
    GetMaxAccountPaymentRequestDetails: 'getMaxAccountPaymentRequestDetails',
    CancelAccountCrmLead: 'cancelAccountCrmLead',
    getPaymentsFetchData: 'getPaymentsFetchData',
    cancelPaymentRequest: 'cancelPaymentRequest',
    GetMaxAccountDetails: 'getMaxAccountDetails',
    GetMaxAccountRecommendations: 'getMaxAccountRecommendations',
    WithdrawalFromMaxAccount: 'withdrawalFromMaxAccount',
    GetEzcountPortalSSOLink: 'getEzcountPortalSSOLink',
    GetMaxAccountNewCreditByTran: 'getMaxAccountNewCreditByTran',
    GetBusinessAuthorizations: 'getBusinessAuthorizations'
};

@Injectable({
    providedIn: 'root'
})
export class MaxAccountService {
    showLoader: EventEmitter<boolean> = new EventEmitter<boolean>();
    loaderEnabled = true;
    isPandingTabActive = false;
    maxAccountDetails: MaxAccountDetailsResponse;
    paymentRequests: PaymentRequest[];
    maxAccountPaymentsDetails: IGetMaxAccountPaymentsDetails;
    maxAccountDashboardContent: IGetMaxAccountDashboardContent;
    withdrawalSuccessData: IWithdrawalFromMaxAccountSuccessPageData;
    sortedTransactions: SortedTransaction[];
    filterDates: any;

    withdrawalRC: number;

    choosePayLink: string;
    linkData: {
        amount: number;
        description: string;
        name?: string;
        mail?: string;
        phoneNumber?: string;
        isRestore?: boolean;
        reminderUrl?: string;
        comment?: string;
        maxPayments?: string;
        fromSendLink?: boolean;
    };

    filterOpen = new EventEmitter<boolean>();

    // ** component related properties ** //
    // PaymentPageComponent //
    closeTabAfterSuccess: boolean;

    constructor(private httpSvc: HttpService, public appConfigsService: AppConfigsService) {}

    getMaxAccountDetails(getMaxAccountDetailsReq?: {
        fromDate?: Date;
        toDate?: Date;
        isMinMode?: boolean;
    }): Observable<IResponseLower<MaxAccountDetailsResponse>> {
        return this.httpSvc.postLower<MaxAccountDetailsResponse>(
            `${this.appConfigsService.appConfigs.apiUrl}/${API_ROUTE_PREFIX}/${API_ENDPOINTS.GetMaxAccountDetails}`,
            getMaxAccountDetailsReq
        );
    }

    //   return this.httpSvc.getWithSsrCache(`${this.appConfigsService.appConfigs.apiUrl}/registered/insurance/getContents`, 'insuranceContents');

    getMaxAccountPaymentsDetails(): Observable<IResponseLower<IGetMaxAccountPaymentsDetails>> {
        return this.httpSvc.getLowerWithSsrCache(
            `${this.appConfigsService.appConfigs.apiUrl}/${API_ROUTE_PREFIX}/${API_ENDPOINTS.GetMaxAccountPaymentsDetails}`,
            'getMaxAccountPaymentsDetails'
        );
    }

    getMaxAccountDashboardContent(): Observable<IResponseLower<IGetMaxAccountDashboardContent>> {
        return this.httpSvc.getLowerWithSsrCache(
            `${this.appConfigsService.appConfigs.apiUrl}/${API_ROUTE_PREFIX}/${API_ENDPOINTS.GetMaxAccountDashboardContent}`,
            'getMaxAccountDashboardContent'
        );
    }

    getPaymentClearingUrl(
        req: IGetPaymentClearingUrlPayload
    ): Observable<IResponseLower<IGetPaymentClearingUrlResponse>> {
        return this.httpSvc.postLower<any>(
            `${this.appConfigsService.appConfigs.apiUrl}/${API_ROUTE_PREFIX}/${API_ENDPOINTS.GetPaymentClearingUrl}`,
            req
        );
    }

    validatePayment(): Observable<IResponseLower<IValidatePaymentResponse>> {
        return this.httpSvc.postLower<any>(
            `${this.appConfigsService.appConfigs.apiUrl}/${API_ROUTE_PREFIX}/${API_ENDPOINTS.ValidatePayment}`,
            null
        );
    }

    createPaymentUrlAndSend(
        req: ICreatePaymentUrlAndSendInput
    ): Observable<IResponseLower<ICreatePaymentUrlAndSendResponse>> {
        this.showLoader.emit(true);
        // return this.httpSvc.postLower<boolean>(`${this.appConfigsService.appConfigs.apiUrl}/${API_ROUTE_PREFIX}/${API_ENDPOINTS.CreatePaymentUrlAndSend}`, null).pipe(
        //    tap((x) => {
        //        this.showLoader.emit(false);

        //    })
        // );

        return this.httpSvc
            .postLower<any>(
                `${this.appConfigsService.appConfigs.apiUrl}/${API_ROUTE_PREFIX}/${API_ENDPOINTS.CreatePaymentUrlAndSend}`,
                req
            )
            .pipe(
                tap((x) => {
                    this.showLoader.emit(false);
                })
            );
    }

    getMaxAccountPaymentRequestDetails(
        req: IGetMaxAccountPaymentRequestDetailsInput
    ): Observable<IResponseLower<IGetMaxAccountPaymentRequestDetails>> {
        return this.httpSvc.postLower<any>(
            `${this.appConfigsService.appConfigs.apiUrl}/${API_ROUTE_PREFIX}/${API_ENDPOINTS.GetMaxAccountPaymentRequestDetails}`,
            req
        );
    }

    cancelAccountCrmLead(): Observable<IResponseLower<ICancelAccountCrmLeadResponse>> {
        return this.httpSvc.postLower<any>(
            `${this.appConfigsService.appConfigs.apiUrl}/${API_ROUTE_PREFIX}/${API_ENDPOINTS.CancelAccountCrmLead}`,
            null
        );
    }

    getPaymentsFetchData(): Observable<IResponseLower<GetPaymentsFetchDataResponse>> {
        return this.httpSvc.postLower<any>(
            `${this.appConfigsService.appConfigs.apiUrl}/${API_ROUTE_PREFIX}/${API_ENDPOINTS.getPaymentsFetchData}`,
            null
        );
    }

    cancelPaymentRequest(req: any): Observable<IResponseLower<any>> {
        return this.httpSvc.postLower<any>(
            `${this.appConfigsService.appConfigs.apiUrl}/${API_ROUTE_PREFIX}/${API_ENDPOINTS.cancelPaymentRequest}`,
            req
        );
    }

    getMaxAccountRecommendations(): Observable<IResponseLower<IMaxAccountRecommendationsData>> {
        return this.httpSvc.getLower<any>(
            `${this.appConfigsService.appConfigs.apiUrl}/${API_ROUTE_PREFIX}/${API_ENDPOINTS.GetMaxAccountRecommendations}`
        );
    }

    changeFilterStateCombo(isOpen: boolean, isCloseFromBackButton: boolean = false): void {
        this.filterOpen.emit(isOpen);
    }

    withdrawalFromMaxAccount(req: IWithdrawalFromMaxAccountInput): Observable<IResponseLower<any>> {
        return this.httpSvc.postLower<any>(
            `${this.appConfigsService.appConfigs.apiUrl}/${API_ROUTE_PREFIX}/${API_ENDPOINTS.WithdrawalFromMaxAccount}`,
            req
        );
    }

    getMessageData(type: MaxAccountMassageTypes): IMaxAccountMessage {
        return MAX_ACCOUNT_MASSAGE_PAGE_STATES[type];
    }

    getMessageModalConfig(type: MaxAccountMassageTypes): IModalConfig {
        return {
            content: AlertStatusPopupComponent,
            type: 'maxAccountPopup',
            options: {
                initialState: {
                    data: this.getMessageData(type)
                } as Object,
                ignoreBackdropClick: true
            }
        };
    }

    getDynamicPopupConfig(type: MaxAccountMassageTypes, value: string) {
        const configOrigin = this.getMessageModalConfig(type);
        const config = JSON.parse(JSON.stringify(configOrigin)) as IModalConfig;
        config['content'] = configOrigin.content;
        this.setDynamicText(config, 'text', value);

        if (type === MaxAccountMassageTypes.PaymentCancelSuccess) {
            this.setDynamicText(config, 'title', value);
        }

        return config;
    }

    isEqual(a: object, b: object): boolean {
        return JSON.stringify(a) === JSON.stringify(b);
    }

    setDynamicText(config: any, field: string, value: string) {
        config.options.initialState['data'][field] = config.options.initialState['data'][field].replace(
            '@extraData1@',
            value
        );
    }

    getEzcountPortalSSOLink(): Observable<IResponseLower<string>> {
        return this.httpSvc.postLower<any>(
            `${this.appConfigsService.appConfigs.apiUrl}/${API_ROUTE_PREFIX}/${API_ENDPOINTS.GetEzcountPortalSSOLink}`,
            null
        );
    }

    getMaxAccountNewCreditByTran(
        req: IGetMaxAccountNewCreditByTranRequest
    ): Observable<IResponseLower<IGetMaxAccountNewCreditByTranData>> {
        return this.httpSvc.postLower<IGetMaxAccountNewCreditByTranData>(
            `${this.appConfigsService.appConfigs.apiUrl}/${API_ROUTE_PREFIX}/${API_ENDPOINTS.GetMaxAccountNewCreditByTran}`,
            req
        );
    }

    getBusinessAuthorizations(): Observable<IResponseLower<IMerApprovalsNoTrans[]>> {
        return this.httpSvc.postLower<any>(
            `${this.appConfigsService.appConfigs.apiUrl}/${API_ROUTE_PREFIX}/${API_ENDPOINTS.GetBusinessAuthorizations}`,
            null
        );
    }
}
