export const validators = {
    onlyDigits: '^[0-9]+$',
    // en.wikipedia.org/wiki/Unicode_and_HTML_for_the_Hebrew_alphabet/
    onlyHebrew: '([\u0590-\u05FF-\'" ]+$)',
    onlyEnglish: '([a-zA-Z]+$)',
    onlyEnglishWithSpaces: '([a-zA-Z\\s]+$)',
    email: '^[A-Za-z0-9._%-]+@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{1,4}$',
    verifySmsCode: '^[0-9]{5}$',
    cardNumber: '^[0-9]{8}$',
    accountNumber: '^[0-9]*$',
    id: '^[0-9]*$',
    phoneNumber: '^05\\d{8}$',
    date: '^[0-9]{2,2}/[0-9]{2,2}',
    password: '^[a-zA-Z0-9_!@#$%^&amp;*)(+]{8,14}$',
    passwordMustChars: '^(?=.*?[0-9])(?=.*?[A-Za-z]).+$',
    userNameLegalChars: '^[a-zA-Z0-9-_.@]{6,55}$',
    userNameIllegalPrefixWS: '^(?!ws|wS|Ws|WS).*',
    hebrewAndEnglish: '^[a-zA-Z\u0590-\u05FF\u200f\u200e ]+$'
};
