<div class="my-points" *ngIf="showMyPoints && !reload">
    <div class="container">
        <h2>נקודות והחזרים לזכותי</h2>

        <div class="card-details">
            <div class="card-box-wrapper" *appLoader="myPointsIsLoaded; template: pointsLoaderTemplateEl">
                <div class="only-card-wrapper">
                    <ngx-slick-carousel
                        class="autoplay clearfix slider"
                        #slickModal="slick-carousel"
                        [config]="myPointsSildeConfig"
                        *appIfStable
                    >
                        <div ngxSlickItem
                             class="card card-box"
                             [ngClass]="{'desktop-hide' : j >3 && !this.isShowAllPoints,'one-card-points': this.cardPoints.length === 1,'one-row-cards-points' : this.cardPoints.length < 3}"
                            *ngFor="let point of this.cardPoints; index as j">
                            <div class="card-detail-wrapper">
                                <img *ngIf="point?.CardImage && point?.CardImage.length > 0"
                                     src="{{ point?.CardImage }}"
                                     alt=""
                                     class="card-pic" />
                                <span class="name" *ngIf="this.isMultUsers">{{ point.OwnerFullName }}</span>
                                <h4>{{ point.Last4Digits + ' ' + point.CardName }}</h4>
                            </div>
                            <div class="obligation">
                                <div class="sum" *ngIf="point.CardPoints !== null">
                                    <span class="title">נקודות DREAM CARD</span>
                                    <span class="price">{{ point.CardPoints | number }}</span>
                                </div>
                                <div class="sum" *ngIf="point.GreenCardPoints !== null">
                                    <span class="title">יתרת נקודות מועדון +WINCARD</span>
                                    <span class="price">{{ point.GreenCardPoints | number }}</span>
                                </div>
                                <div class="sum" *ngIf="point.CashBackPoints !==null && point.CashBackPoints >= 0 && !point.IsPayBack && !point.IsTotalMaxBack">
                                    <span class="title">מגיע לך</span>
                                    <span class="price"><span>₪</span>{{ point.CashBackPoints | number }}</span>
                                    <div *ngIf="point.CashBackPoints >= 75 && point.IsRegisterdCard">
                                        <a href="{{
                                                infoRoot
                                            }}/Registred/CashBack/LoadCashback.aspx?SourceGA=ClickOnTotalPayBack"
                                           class="default-link"
                                           title=""
                                           appGtm
                                           [data]="{
                                                category: 'New website - registered',
                                                action: 'Click on TotalPayBack',
                                                label:
                                                    infoRoot +
                                                    '/Registred/CashBack/LoadCashback.aspx?SourceGA=ClickOnTotalPayBack'
                                            }"
                                           listen="onclick">הזמנת gift card</a>
                                    </div>
                                </div>
                                <div class="sum" *ngIf="point.TotalMaxBackPoints !== null && point.TotalMaxBackPoints >= 0 && point.IsTotalMaxBack">
                                    <span class="title">מגיע לך</span>
                                    <span class="price">{{ point.TotalMaxBackPoints | number }}<span class="points">נקודות</span></span>
                                    <a class="default-link" *ngIf="point.IsRegisterdCard"
                                       (click)="goToTotalMaxback()"
                                       listen="onclick">להמרת נקודות</a>
                                </div>
                                <div class="sum" *ngIf="point.CashBackPoints !== null && point.CashBackPoints >= 0 && point.IsPayBack">
                                    <span class="title">כסף שחוזר אליך</span>
                                    <span class="price"><span>₪</span>{{ point.CashBackPoints | number }}</span>
                                    <a href="https://www.pay-back.co.il?SourceGA=ClickOnPayBack-Money"
                                       class="default-link"
                                       target="_blank"
                                       appGtm
                                       [data]="{
                                            category: 'New website - registered',
                                            action: 'Click on PayBack-Money',
                                            label: infoRoot + 'https://www.pay-back.co.il?SourceGA=ClickOnPayBack-Money'
                                        }"
                                       listen="onclick">קבלת החזר לכרטיס שלי</a>
                                </div>
                                <div *ngIf="!point.IsSkyMax && point.FlightPoints !== null && point.FlightPoints >= 0"
                                     class="sum">
                                    <span class="title">נקודות תעופה</span>
                                    <span class="price">
                                        {{ point.FlightPoints | number }}<span class="flight-icon"></span>
                                    </span>
                                    <div *ngIf="point.FlightPoints > 0 && point.IsRegisterdCard">
                                        <a href="{{ infoRoot }}/Registred/Transactions/PointsConvert.aspx?Index={{
                                                point.CardIndex
                                            }}&SourceGA=ClickOnFlight"
                                           class="default-link"
                                           appGtm
                                           [data]="{
                                                category: 'New website - registered',
                                                action: 'Click on Flight',
                                                label:
                                                    infoRoot +
                                                    '/Registred/Transactions/PointsConvert.aspx?SourceGA=ClickOnFlight'
                                            }"
                                           listen="onclick">המרת נקודות</a>
                                    </div>
                                </div>
                                <div *ngIf="point.IsSkyMax && point.SkyMaxPoints !== null" class="sum">
                                    <span class="title">נקודות SKY<strong>MAX</strong></span>
                                    <span class="price">
                                        {{ point.SkyMaxPoints | number }}<span class="flight-icon"></span>
                                    </span>
                                    <div *ngIf="point.SkyMaxVacationOrderUrl !== null">
                                        <a href="{{ point.SkyMaxVacationOrderUrl }}?SourceGa=SkyMax"
                                           target="_blank"
                                           class="default-link"
                                           appGtm
                                           [data]="{
                                                category: 'New website - registered',
                                                action: 'Click on SkyMax',
                                                label: point.SkyMaxVacationOrderUrl + '?SourceGa=SkyMax'
                                            }"
                                           listen="onclick">להזמנת החופשה הבאה שלי</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            ngxSlickItem
                            *ngIf="!uiHelper.IsMobile(770)"
                            [ngClass]="{ 'card card-box empty-card': true }"
                            [hidden]="this.cardPoints?.length <= 1 ||this.cardPoints?.length % 2 === 0 ||
                                (this.cardPoints?.length > 4 && !this.isShowAllPoints)">
                            <div class="card-detail-wrapper"></div>
                            <div class="obligation"></div>
                        </div>
                    </ngx-slick-carousel>
                </div>
                <div>
                    <div
                        [ngClass]="{ 'show-all-cards': true, 'show-less-card': this.isShowAllPoints }"
                        (click)="togglePoints()"
                        *ngIf="this.isShowPointsText"
                        appGtm
                        [data]="{
                            category: 'New website - registered',
                            action: 'Accumulations',
                            label: this.isShowAllPoints ? 'Expend' : 'Shrink'
                        }"
                        listen="onclick"
                    >
                        <span *ngIf="this.isShowAllPoints">תראו לי פחות</span>
                        <span *ngIf="!this.isShowAllPoints">תראו לי הכל</span>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="showRecommendation">
                <app-recommendation-box
                    *appLoader="recommendationIsLoaded; template: recommendationLoaderTemplateEl"
                    class="recommendation-box"
                    [recommendation]="recommendationsToShow[0]"
                    [backgroundColor]="
                        backgroundFirstPointRec?.BackgroundColor ? backgroundFirstPointRec?.BackgroundColor : '#e5e5e5'"
                    [wideTitle]="true"
                ></app-recommendation-box>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #pointsLoaderTemplateEl>
    <div class="card-box-wrapper">
        <div class="only-card-wrapper">
            <div class="autoplay clearfix slider mobile-hide">
                <div class="card card-box app-loader-blink"></div>
                <div class="card card-box app-loader-blink"></div>
                <div class="card card-box app-loader-blink"></div>
                <div class="card card-box app-loader-blink"></div>
            </div>

            <ngx-slick-carousel
                class="autoplay clearfix slider desktop-hide"
                #slickModal="slick-carousel"
                [config]="myPointsSildeConfig"
                *appIfStable
            >
                <div ngxSlickItem class="card card-box app-loader-blink"></div>
                <div ngxSlickItem class="card card-box app-loader-blink"></div>
            </ngx-slick-carousel>
        </div>
    </div>
</ng-template>

<ng-template #recommendationLoaderTemplateEl>
    <app-recommendation-box
        class="recommendation-box recommendation-box-template app-loader-blink"
    ></app-recommendation-box>
</ng-template>
