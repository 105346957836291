import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    AfterViewInit,
    OnDestroy
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormHelperService } from '../../../../shared/cardholders-core/services/form-helper.service';
import { AuthService } from '../../../../shared/cardholders-core/services/auth.service';
import { ModalService } from '../../../../shared/modules/bs-modal/services/modal.service';
import { Router } from '@angular/router';
import { AppConfigsService } from '../../../../config/app-configs.service';
import { IResponse } from '../../../../shared/cardholders-core/services/http-service.service';
import { LoginErrorScreenMessages } from '../login-messages.consts';
import { validators } from '../../../../shared/consts/validators';
import { BaseLoginComponent } from '../base-components/base-login.component';
import { UiHelperService } from '../../../../shared/cardholders-core/services/ui-helper.service';
import { GtmService } from '../../../../shared/cardholders-core/services/gtm.service';
import { LoginService } from '../../login.service';
import { ILoginResult, LoginStatus } from '../../../../shared/cardholders-core/models/login-result';
import { Subject, Subscription } from 'rxjs';
import { LoginWithMaxService } from '../../../login-with-max/login-with-max.service';
import { LoginPageService } from '../../login-page/login-page.service';
import { OpenBankingService } from '../../../open-banking/open-banking.service';
import { TrusteerSnippetsService } from '../../../../shared/cardholders-core/services/trusteer-snippets.service';
import { BiometricPopupService } from 'ClientApp/app/shared/cardholders-core/services/biometricPopup.service';
import { isNullOrUndefined } from 'core-max-lib';

@Component({
    selector: 'app-otp-login-form',
    templateUrl: './otp-login-form.component.html',
    styleUrls: ['./otp-login-form.component.scss']
})
export class OtpLoginFormComponent extends BaseLoginComponent implements OnInit, AfterViewInit, OnDestroy {
    userId: string;
    step = 1;
    idForm: FormGroup;
    verifyForm: FormGroup;
    emailForm: FormGroup;
    confirmTermsUrl: string;
    isMobile: boolean;
    @Output()
    stepChanged: EventEmitter<number> = new EventEmitter();
    @Input()
    userIdFromUserLoginForm: Subject<string>;
    @ViewChild('id')
    idInputEl: ElementRef;
    @ViewChild('verifyCode')
    verifyCodeInputEl: ElementRef;
    @ViewChild('email')
    emailInputEl: ElementRef;
    @Input()
    otpTabClicked: Subject<string>;
    isExtraAuthRequired: boolean;
    titleText: string;
    isMailOtpEnabled: boolean;
    verifyCodePlaceholderText: string;
    verifyCodeTitle: string;
    isInMailMode = false;
    subscribers: Subscription[] = [];
    loginPageMode: boolean;
    @Input() dontShowEmptyFieldError: boolean;
    @Input() sendOtpId: string;
    registrationUrl: string;
    constructor(
        private formHelperService: FormHelperService,
        protected userLoginService: LoginService,
        protected authService: AuthService,
        protected modalSvc: ModalService,
        protected router: Router,
        protected appConfigsService: AppConfigsService,
        protected uiHelper: UiHelperService,
        protected gtmService: GtmService,
        protected loginWithMaxSvc: LoginWithMaxService,
        protected openBankingSvc: OpenBankingService,
        protected loginPageSvc: LoginPageService,
        private trusteerSnippetsService: TrusteerSnippetsService,
        protected bioSrv: BiometricPopupService
    ) {
        super(
            router,
            authService,
            modalSvc,
            appConfigsService,
            gtmService,
            userLoginService,
            uiHelper,
            loginWithMaxSvc,
            openBankingSvc,
            bioSrv
        );
    }

    ngOnInit(): void {
        this.isMailOtpEnabled = this.appConfigsService.appConfigs.FLAG_ISMailOtpEnabled;
        this.isLoginWithMax = this.loginWithMaxSvc.isLoginWithMaxMode();
        this.isOpenBankingLogin = this.openBankingSvc.isOpenBankingLoginMode();
        this.loginPageMode = this.loginPageSvc.isLoginPageMode();
        if (this.isLoginWithMax) {
            this.titleText = this.isMailOtpEnabled
                ? 'נכנסים לאזור האישי עם קוד חד פעמי שנשלח לנייד או למייל המעודכן במערכות max'
                : 'נכנסים לאזור האישי עם קוד חד פעמי שנשלח לנייד המעודכן במערכות max';
        } else {
            this.titleText = this.isMailOtpEnabled
                ? 'נכנסים לאזור האישי עם קוד חד פעמי שמקבלים לנייד או למייל'
                : 'נכנסים לאזור האישי עם קוד חד פעמי שמקבלים לנייד';
        }
        this.isMobile = this.uiHelper.IsMobileByScreen(768);
        this.confirmTermsUrl = `${this.appConfigsService.appConfigs.contentRoot}/he-IL/Pages/Regulations.aspx?SourceGA=OTPLoginPhase2`;
        this.idForm = new FormGroup({
            id: new FormControl(null, [Validators.pattern(validators.id), Validators.required])
        });
        this.emailForm = new FormGroup({
            email: new FormControl('', {
                validators: [Validators.pattern(validators.email), Validators.required],
                updateOn: 'submit'
            })
        });
        this.verifyForm = new FormGroup({
            verifyCode: new FormControl(null, [Validators.pattern(validators.verifySmsCode), Validators.required]),
            verifyDigitsRadio: new FormControl('card8Digits'),
            verifyDigitsValue: new FormControl(null, [Validators.required, Validators.pattern(validators.cardNumber)]),
            confirmTerms: new FormControl(null, Validators.required)
        });
        this.subscribers.push(
            this.userIdFromUserLoginForm.subscribe((userId) => {
                this.getUserIdFromUserLoginTab(userId);
            })
        );
        this.subscribers.push(
            this.otpTabClicked.subscribe(() => {
                this.setFocusOnFirstInput();
            })
        );

        this.isExtraAuthRequired = this.authService.isUserAuthenticated();

        if (!isNullOrUndefined(this.sendOtpId) && this.sendOtpId !== '') {
            this.sendMeCode();
        }
        this.registrationUrl = this.appConfigsService.appConfigs.FLAG_ISEnableNewRegistrationScreen ?
            `${this.appConfigsService.appConfigs.baseUiRoot}/register-website/choose-identity`
            : `${this.appConfigsService.appConfigs.infoRoot}/Anonymous/Registration.aspx?SourceGA=PasswordLogin`;
    }

    

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.setFocusOnFirstInput();
        }, 1000);
    }

    ngOnDestroy(): void {
        this.subscribers.forEach((subscriber) => subscriber.unsubscribe());
    }

    setFocusOnFirstInput(): void {
        // if (this.step === 1) {
        //     this.setFocusOnElement(this.idInputEl);
        // } else if (this.step === 1.5) {
        //     this.setFocusOnElement(this.emailInputEl);
        // } else {
        //     this.setFocusOnElement(this.verifyCodeInputEl);
        // }
    }

    getUserIdFromUserLoginTab(userId: string): void {
        this.idForm.controls['id'].setValue(userId);
    }

    showIdErrorMessage(): boolean {
        return this.isCodeNotByPattern() || this.isCodeEmpty();
    }

    showVerify8DigitsErrorMessage(): boolean {
        return (
            this.verifyForm.get('verifyDigitsRadio').value === 'card8Digits' &&
            (this.isCard8DigitsNotByPattern() || this.isVerifyDigitsValueEmpty())
        );
    }

    showVerifyAccountNumberErrorMessage(): boolean {
        return (
            this.verifyForm.get('verifyDigitsRadio').value === 'accountNumber' &&
            (this.isAccountNumberNotByPattern() || this.isVerifyDigitsValueEmpty())
        );
    }

    isIdEmpty(): boolean {
        if (this.dontShowEmptyFieldError) {
            return false;
        }
        return this.formHelperService.isFormControlEmptyAfterTouched(this.idForm.get('id'));
    }

    isEmailEmpty(): boolean {
        return this.formHelperService.isFormControlEmptyAfterTouched(this.emailForm.get('email'));
    }

    isCodeEmpty(): boolean {
        return this.formHelperService.isFormControlEmptyAfterTouched(this.verifyForm.get('verifyCode'));
    }

    isVerifyDigitsValueEmpty(): boolean {
        return this.formHelperService.isFormControlEmptyAfterTouched(this.verifyForm.get('verifyDigitsValue'));
    }

    isIdNotValidByPattern(): boolean {
        return !this.formHelperService.isFormControlValidByPattern(this.idForm.get('id'));
    }

    isEmailNotValidByPattern(): boolean {
        return !this.formHelperService.isFormControlValidByPattern(this.emailForm.get('email'));
    }

    isCodeNotByPattern(): boolean {
        return this.isControlNotValidByPattern('verifyCode');
    }

    isCard8DigitsNotByPattern(): boolean {
        if (this.verifyForm.get('verifyDigitsRadio').value === 'card8Digits') {
            return this.isControlNotValidByPattern('verifyDigitsValue');
        }
        return false;
    }

    isAccountNumberNotByPattern(): boolean {
        if (this.verifyForm.get('verifyDigitsRadio').value === 'accountNumber') {
            return this.isControlNotValidByPattern('verifyDigitsValue');
        }
        return false;
    }

    isControlNotValidByPattern(controlName: string): boolean {
        return (
            !this.formHelperService.isFormControlValidByPattern(this.verifyForm.get(controlName)) &&
            this.verifyForm.get(controlName).touched
        );
    }

    isTermsCheckboxNotChecked(): boolean {
        return (
            this.verifyForm.get('confirmTerms').hasError('required') &&
            (this.verifyForm.get('confirmTerms').touched || this.verifyForm.get('confirmTerms').dirty)
        );
    }

    onSendMeCodeClicked(): void {
        this.verifyCodePlaceholderText = 'קוד אימות שקיבלת למכשיר הנייד';
        this.verifyCodeTitle = 'קוד לכניסה חד פעמית נשלח לנייד המעודכן אצלנו';
        this.hideErrorMsg();
        this.formHelperService.validateAllFormFields(this.idForm);
        if (!this.idForm.valid) {
            return;
        }
        this.updateLoaderStatusEvent.emit(false);
        this.subscribers.push(
            this.userLoginService.loginOtp(this.idForm.get('id').value).subscribe((data: IResponse<any>) => {
                switch (data.ReturnCode) {
                    case 0:
                        this.isInMailMode = false;
                        this.userId = this.idForm.get('id').value;
                        this.step = 2;
                        this.stepChanged.emit(this.step);
                        this.pushDirectiveGtmWorkflow(
                            'New website - log in',
                            'OTP - phase 1',
                            'Send OTP',
                            '4',
                            'LoginOTP',
                            '2',
                            '3'
                        );
                        this.pushDirectiveGtmWorkflow(
                            'New website - log in',
                            'OTP - phase 2',
                            'Show',
                            '4',
                            'LoginOTP',
                            '2',
                            '3'
                        );
                        this.trusteerSnippetsService.executeTagmastersEvent('otp');
                        break;
                    case 10:
                        this.loginFailed.emit(LoginErrorScreenMessages.lockedCantRelease13);
                        break;
                    case 15:
                        this.loginFailed.emit(LoginErrorScreenMessages.getOtpCodeLimitIdLocked15);
                        this.pushDirectiveGtm(
                            'New website - log in',
                            'OTP errors',
                            'Too many attempts error – temporary lock'
                        );
                        break;
                    case 9:
                        this.loginFailedWithTechnicalError.emit();
                        break;
                    default:
                        break;
                }
                this.updateLoaderStatusEvent.emit(true);
            })
        );
    }

    sendMeCode(): void {
        this.verifyCodePlaceholderText = 'קוד אימות שקיבלת למכשיר הנייד';
        this.verifyCodeTitle = 'קוד לכניסה חד פעמית נשלח לנייד המעודכן אצלנו';
        this.hideErrorMsg();
        this.formHelperService.validateAllFormFields(this.idForm);

        this.updateLoaderStatusEvent.emit(false);
        this.subscribers.push(
            this.userLoginService.loginOtp(this.sendOtpId).subscribe((data: IResponse<any>) => {
                switch (data.ReturnCode) {
                    case 0:
                        this.isInMailMode = false;
                        this.userId = this.sendOtpId;
                        this.step = 2;
                        this.stepChanged.emit(this.step);
                        this.pushDirectiveGtmWorkflow(
                            'New website - log in',
                            'OTP - phase 1',
                            'Send OTP',
                            '4',
                            'LoginOTP',
                            '2',
                            '3'
                        );
                        this.pushDirectiveGtmWorkflow(
                            'New website - log in',
                            'OTP - phase 2',
                            'Show',
                            '4',
                            'LoginOTP',
                            '2',
                            '3'
                        );
                        this.trusteerSnippetsService.executeTagmastersEvent('otp');
                        break;
                    case 10:
                        this.loginFailed.emit(LoginErrorScreenMessages.lockedCantRelease13);
                        break;
                    case 15:
                        this.loginFailed.emit(LoginErrorScreenMessages.getOtpCodeLimitIdLocked15);
                        this.pushDirectiveGtm(
                            'New website - log in',
                            'OTP errors',
                            'Too many attempts error – temporary lock'
                        );
                        break;
                    case 9:
                        this.loginFailedWithTechnicalError.emit();
                        break;
                    default:
                        break;
                }
                this.updateLoaderStatusEvent.emit(true);
            })
        );
    }

    onSendMeMailClicked(): void {
        this.hideErrorMsg();
        this.formHelperService.validateAllFormFields(this.emailForm);
        if (!this.emailForm.valid) {
            return;
        }
        this.updateLoaderStatusEvent.emit(false);
        const email = this.emailForm.get('email').value;
        this.subscribers.push(
            this.userLoginService.verifyEmail(email).subscribe((res) => {
                switch (res.ReturnCode) {
                    case 0:
                    case 12:
                        this.isInMailMode = true;
                        this.step = 2;
                        this.stepChanged.emit(this.step);
                        this.pushDirectiveGtmWorkflow(
                            'New website - log in',
                            'OTP - phase 1',
                            'Send OTP',
                            '4',
                            'LoginOTP',
                            '2',
                            '3'
                        );
                        this.pushDirectiveGtmWorkflow(
                            'New website - log in',
                            'OTP - phase 2',
                            'Show',
                            '4',
                            'LoginOTP',
                            '2',
                            '3'
                        );
                        this.pushDirectiveGtmWorkflow(
                            'New website - log in',
                            'OTP - phase 1 - mail',
                            'Send code to mail',
                            '4',
                            'LoginOTP_By_Mail',
                            '2',
                            '3'
                        );
                        this.trusteerSnippetsService.executeTagmastersEvent('otp');
                        break;
                    case 10:
                        this.loginFailed.emit(LoginErrorScreenMessages.lockedCantRelease13);
                        break;
                    case 11:
                        this.loginFailed.emit(LoginErrorScreenMessages.getOtpCodeLimitIdLocked15);
                        this.pushDirectiveGtm(
                            'New website - log in',
                            'OTP errors',
                            'Too many attempts error – temporary lock'
                        );
                        break;
                    case 9:
                        this.loginFailedWithTechnicalError.emit();
                        break;
                    default:
                        break;
                }
                this.updateLoaderStatusEvent.emit(true);
            })
        );
    }

    sendMailOtp(): void {
        this.verifyCodePlaceholderText = 'קוד אימות שקיבלת למייל';
        this.verifyCodeTitle = 'קוד לכניסה חד פעמית נשלח לכתובת המייל הרשומה לדפי הפירוט';
        this.hideErrorMsg();
        this.formHelperService.validateAllFormFields(this.idForm);
        if (!this.idForm.valid) {
            return;
        }
        this.updateLoaderStatusEvent.emit(false);
        this.subscribers.push(
            this.userLoginService.loginMailOtp(this.idForm.get('id').value).subscribe((data: IResponse<any>) => {
                switch (data.ReturnCode) {
                    case 0:
                        this.userId = this.idForm.get('id').value;
                        this.step = 1.5;
                        this.stepChanged.emit(this.step);
                        this.pushDirectiveGtm('New website - log in', 'OTP - phase 1 - mail', 'Show - fill mail');
                        this.pushDirectiveGtmWorkflow(
                            'New website - log in',
                            'OTP - phase 1',
                            'Send OTP by mail',
                            '4',
                            'LoginOTP_By_Mail',
                            '1',
                            '3'
                        );
                        break;
                    case 10:
                        this.loginFailed.emit(LoginErrorScreenMessages.lockedCantRelease13);
                        break;
                    case 15:
                        this.loginFailed.emit(LoginErrorScreenMessages.getOtpCodeLimitIdLocked15);
                        this.pushDirectiveGtm(
                            'New website - log in',
                            'OTP errors',
                            'Too many attempts error – temporary lock'
                        );
                        break;
                    case 9:
                        this.loginFailedWithTechnicalError.emit();
                        break;
                    default:
                        break;
                }
                this.updateLoaderStatusEvent.emit(true);
            })
        );
    }

    onLoginClicked(): void {
        this.hideErrorMsg();
        this.formHelperService.validateAllFormFields(this.verifyForm);
        if (!this.verifyForm.valid) {
            return;
        }
        this.updateLoaderStatusEvent.emit(false);
        const isTypeIsCard8Digits = this.verifyForm.get('verifyDigitsRadio').value === 'card8Digits' ? true : false;
        const card8Digits = isTypeIsCard8Digits ? this.verifyForm.get('verifyDigitsValue').value : null;
        const accountNumber = isTypeIsCard8Digits ? '0' : this.verifyForm.get('verifyDigitsValue').value;
        this.subscribers.push(
            this.userLoginService
                .loginOtpVerify(
                    this.userId,
                    card8Digits,
                    this.verifyForm.get('verifyCode').value,
                    accountNumber,
                    isTypeIsCard8Digits
                )
                .subscribe((data: IResponse<ILoginResult>) => {
                    this.checkLoginStatus(data.Result, this.updateLoaderStatusEvent);
                    if (
                        data.Result.LoginStatus === LoginStatus.success ||
                        data.Result.LoginStatus === LoginStatus.NeedToShowInsurance
                    ) {
                        if (isTypeIsCard8Digits) {
                            this.pushDirectiveGtmWorkflow(
                                'New website - log in',
                                'OTP - phase 2' + (this.isInMailMode ? ' - mail' : ''),
                                'Log in 8 digits',
                                '4',
                                'LoginOTP' + (this.isInMailMode ? '_By_Mail' : ''),
                                '3',
                                '3'
                            );
                        } else {
                            this.pushDirectiveGtmWorkflow(
                                'New website - log in',
                                'OTP - phase 2' + (this.isInMailMode ? ' - mail' : ''),
                                'Log in bank account',
                                '4',
                                'LoginOTP' + (this.isInMailMode ? '_By_Mail' : ''),
                                '3',
                                '3'
                            );
                        }
                        if (
                            data.Result.LoginStatus === LoginStatus.NeedToShowInsurance &&
                            !this.authService.isContactAuthorization()
                        ) {
                            if (this.isLoginWithMax) {
                                this.userLoginService.loginWithMax();
                            } else if (this.isOpenBankingLogin) {
                                this.openBankingSvc.onOpenBankingLoginSuccess.next(true);
                            } else {
                                if (
                                    data.Result.UserTransferResult &&
                                    data.Result.UserTransferResult.IsTransferMessageNeeded
                                ) {
                                    this.loginService.navigateUserTransferPage(
                                        '/insurance/personal',
                                        true,
                                        this.updateLoaderStatusEvent
                                    );
                                } else {
                                    this.router.navigate(['/insurance/personal']);
                                }
                            }
                        } else {
                            this.userLoginService.navigateReturnUrlAfterLoginSuccess();
                        }
                        this.modalSvc.closeModal();
                    } else if (
                        (this.isLoginWithMax || this.isOpenBankingLogin) &&
                        data.Result.LoginStatus !== LoginStatus.falied
                    ) {
                        this.userLoginService.navigateReturnUrlAfterLoginSuccess();
                    }
                })
        );
    }

    getMaxLengthOfVerifyDigits(): number {
        if (this.verifyForm.get('verifyDigitsRadio').value === 'card8Digits') {
            return 8;
        } else {
            return 9;
        }
    }

    radioOptionClicked(option: string): void {
        if (option === 'card8Digits') {
            this.verifyForm.controls['verifyDigitsValue'].setValidators([
                Validators.required,
                Validators.pattern(validators.cardNumber)
            ]);
        } else {
            this.verifyForm.controls['verifyDigitsValue'].setValidators([
                Validators.required,
                Validators.pattern(validators.accountNumber)
            ]);
        }
        this.verifyForm.controls['verifyDigitsValue'].setValue('');
    }

    goToUserLoginForm(): void {
        this.switchLoginTab.emit(1);
    }

    tryAgainOtpClicked(): void {
        this.step = 1;
        this.stepChanged.emit(this.step);
        this.verifyForm.reset();
        this.idForm.reset();
    }

    idBlured(e: any): void {
        if (e?.relatedTarget?.classList?.contains('skip-blur')) {
            e.relatedTarget.click();
        }
        this.pushDirectiveGtmWorkflow('New website - log in', 'OTP - phase 1', 'TZ type', '4', 'LoginOTP', '1', '3');
    }

    onNotRecivedCodeClicked(): void {
        this.step = 3;
        this.pushDirectiveGtm('New website - log in', 'OTP - phase 2', 'Click on didn’t get code');
    }

    onConfirmTermsClicked(): void {
        this.hideErrorMsg();
    }

    putCaretInTheEnd(event) {
        // Safari Bug
        setTimeout(() => (event.target.value = event.target.value));
    }
}
