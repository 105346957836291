import { EventEmitter, Injectable } from '@angular/core';
import { LoggerService } from '../../../shared/cardholders-core/services/logger.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs-compat';
import { tap } from 'rxjs/operators';
import { AppConfigsService } from '../../../config/app-configs.service';
import { IOnboardingContent } from '../interfaces/onboarding-content.interface';
import { IErrorPage, IErrorPagesContent } from '../interfaces/error-pages-content.interface';
import { ICardColors } from '../interfaces/card-colors-content.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { IChargeableCard } from '../interfaces/chargeable-card.interface';
import { IOfferingCardsProcessResult } from '../interfaces/offering-cards-process-result.interface';
import { DynamicPopupComponent } from '../../../shared/cardholders-core/components/dynamic-popup/dynamic-popup.component';
import { IModalConfig } from '../../../shared/modules/bs-modal/interfaces/modal-config.interface';
import { ModalService } from '../../../shared/modules/bs-modal/services/modal.service';
import { ImyMaxCard } from '../../max-account/interfaces/api-calls.interfaces';
import { IDashboardContent } from '../interfaces/dashboard-content.interface';
import { IGetDigitalFormsResult } from '../interfaces/get-digital-forms-result.interface';
import { IChildSendOtpResult } from '../interfaces/child-send-otp-response';
import { ICalculationMaxMinCharging } from '../interfaces/calculation-max-min-charging';
import { IChildOnboardingContent } from '../interfaces/child-onboarding-content.interface';
import { IChargingForChildCardRequest, TransType } from '../interfaces/charging-for-child-card-request';
import {
    IResponse,
    HttpService,
    IHttpRequestOptions,
    IResponseLower
} from '../../../shared/cardholders-core/services/http-service.service';
import { ICardActionsContent } from '../interfaces/card-actions-content.interface';
import { IIdentity } from '../../../shared/models/identity';
import { ITransactionsCategory } from '../../transactions/transaction-details/models/ITransaction';

// const iioLeavingPopup: IModalConfig = {
//     content: DynamicPopupComponent,
//     type: 'iioLeavingPopup',
//     options: {
//         initialState: {
//             data: {
//                 showCloseButton: false,
//                 closeOnAction: true,
//                 img: '/assets/images/my-max/awesome-owl.svg',
//                 title: 'אנחנו שמחים שהתעניינת </br>ב-MyMAX',
//                 text: `ועצובים שבחרת לא להמשיך</br>
//                 טעות או התחרטת? פשוט ללחוץ על "חזרה לתהליך ההצטרפות"`,
//                 btnText: 'חזרה לתהליך ההצטרפות',
//                 linkText: 'יציאה מתהליך ההצטרפות'
//             }
//         } as Object,
//         ignoreBackdropClick: true
//     }
// };

@Injectable({
    providedIn: 'root'
})
export class MyMaxService {
    baseApi: string;
    showLoader: EventEmitter<{loader: boolean, blank?: boolean}> = new EventEmitter<{loader: boolean, blank?: boolean}>();
    initLoader: EventEmitter<void> = new EventEmitter<void>();
    loadedErrorPages: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    openDialog: EventEmitter<any> = new EventEmitter<any>();
    obContent: IOnboardingContent;
    cardColors: ICardColors;
    chargeableCards: IChargeableCard[];
    selectedCard: IChargeableCard;
    nextPage = '';
    regForm: any;
    error: IErrorPage;
    privateNameEng: string;
    familyNameEng: string;
    isShowLeavingPopup = false;
    canExit: any;
    SingleChargedSum = 0;
    cardDetailsRes: ImyMaxCard[];
    selectedChildCardDetails: ImyMaxCard;
    cardActionsContent: ICardActionsContent;
    chargingNewCard = false;
    MaxMinChargingForChildData: ICalculationMaxMinCharging;
    selectedMyMaxCardIndex: number;
    skippedChargingCard = false;
    mistypedPhoneNum: boolean;
    isSSO = false;
    isPopupNavigation = false;
    errorPagesContent: IErrorPagesContent;
    subs = [];
    permenantDepositReq: {amount: number, day: number, transType: TransType};
    monthlyChargedSum: number;
    phoneNumberForChildActions: string;
    childSendOtpResult: IChildSendOtpResult;
    childObContent: IChildOnboardingContent;

    constructor(
        private appConfigsService: AppConfigsService,
        public logger: LoggerService,
        private modalService: ModalService,
        private router: Router,
        private httpService: HttpService,
        private activeRoute: ActivatedRoute
    ) {
        this.baseApi = `${this.appConfigsService.appConfigs.apiUrl}/registered/myMax`;
    }

    getColorByColorId(colorId: number): string {
        return this.cardColors.colorList.find(c => c.colorId === colorId)?.color;
    }

    isCardNotActiveOrNotActivated(card: ImyMaxCard): boolean {
        return card.blockCode !== ' ' && card.blockCode !== 'Z';
    }

    isDigitalCard(card: ImyMaxCard): boolean {
        return card.digitalCardInd === 'Y' || card.digitalCardInd === 'N' || card.digitalCardInd === 'C';
    }

    getObContent(): Observable<IResponseLower<IOnboardingContent>> {
        this.showLoader.emit({loader: true, blank: true});
        return this.httpService.getLower<IOnboardingContent>(`${this.baseApi}/getObContent`).pipe(
            tap((x) => {
                this.showLoader.emit({loader: false, blank: false});
                if (x.result) {
                    this.obContent = x.result;
                }
            })
        );
    }
    getErrorPagesContent(): Observable<IResponseLower<IErrorPagesContent>> {
        this.showLoader.emit({loader: true, blank: true});
        return this.httpService.getLower<IErrorPagesContent>(`${this.baseApi}/getErrorPagesContent`).pipe(
            tap((x) => {
                this.showLoader.emit({loader: false, blank: false});
                if (x.result) {
                    this.errorPagesContent = x.result;
                    this.loadedErrorPages.next(true);
                    this.error = x.result.errorPages.find( (e: IErrorPage) => e.errorReturnCode === '9' && e.service === 'General');
                }
            })
        );
    }

    getCardColors(): Observable<IResponseLower<ICardColors>> {
        this.showLoader.emit({loader: true, blank: true});
        return this.httpService.getLower<ICardColors>(`${this.baseApi}/getCardColors`).pipe(
            tap((x) => {
                this.showLoader.emit({loader: false, blank: false});
                if (x.result) {
                    this.cardColors = x.result;
                }
            })
        );
    }

    sendOtp(phoneNum: string): Observable<IResponseLower<any>> {
        this.showLoader.emit({loader: true});
        return this.httpService.postLower<any>(`${this.baseApi}/sendOtp`, {'MobilePhoneNumber': phoneNum}).pipe(
            tap((x) => {
                this.showLoader.emit({loader: false});
                console.log('sendOtp response-', x);
                if (x.returnCode !== 0) {
                    this.navigateToErrorPage('sendOtp', x.returnCode);
                }
            })
        );
    }

    childSendOtp(phoneNum: string, captchaRes?: string): Observable<IResponseLower<IChildSendOtpResult>> {
        return this.httpService.postLower<any>(`${this.baseApi}/childSendOtp`,
        {'MobilePhoneNumber': phoneNum, 'captcha': captchaRes}).pipe(
            tap((x) => {
                if (x.returnCode !== 0) {
                    this.navigateToErrorPage('childSendOtp', x.returnCode, true);
                }
            })
        );
    }

    getMyMaxEligibility(): Observable<IResponseLower<any>> {
        this.showLoader.emit({loader: true, blank: true});
        return this.httpService.getLower<any>(`${this.baseApi}/getMyMaxEligibility`).pipe(
            tap((x) => {
                this.showLoader.emit({loader: false, blank: false});
                console.log('getMyMaxEligibility response-', x);
                if (x.returnCode === 99) {
                    this.nextPage = 'personal';
                    this.router.navigate(['homepage/personal']);
                    return;
                }
                if (x.returnCode !== 0) {
                    this.navigateToErrorPage('getMyMaxEligibility', x.returnCode);
                }
            })
        );
    }

    verifyOtp(otp: string): Observable<IResponseLower<any>> {
        this.showLoader.emit({loader: true});
        return this.httpService.postLower<any>(`${this.baseApi}/verifyOtp`, {'OTPcode': otp}).pipe(
            tap((x) => {
                this.showLoader.emit({loader: false});
                console.log('verifyOtp response-', x);
                if (x.returnCode !== 0 && x.returnCode !== 10) {
                    this.navigateToErrorPage('verifyOtp', x.returnCode);
                }
            })
        );
    }

    childVerifyOtp(otp: string): Observable<IResponseLower<IChildSendOtpResult>> {
        this.showLoader.emit({loader: true, blank: true});
        return this.httpService.postLower<IChildSendOtpResult>(`${this.baseApi}/childVerifyOtp`, {'OTPcode': otp}).pipe(
            tap((x) => {
                this.showLoader.emit({loader: false, blank: false});
                this.childSendOtpResult = x.result;
                if (x.returnCode !== 0 && x.returnCode !== 10) {
                    this.navigateToErrorPage('childVerifyOtp', x.returnCode, true);
                }
            })
        );
    }

    getChargeableCards(): Observable<IResponseLower<IChargeableCard[]>> {
        this.showLoader.emit({loader: true});
        return this.httpService.postLower<IChargeableCard[]>(`${this.baseApi}/getChargeableCards`, null).pipe(
            tap((x) => {
                this.showLoader.emit({loader: false});
                if (x.returnCode !== 0) {
                    this.navigateToErrorPage('getChargeableCards', x.returnCode);
                } else {
                    this.chargeableCards = x.result;
                }
            })
        );
    }

    offeringCardsProcess(): Observable<IResponseLower<IOfferingCardsProcessResult>> {
        this.showLoader.emit({loader: true});

        const options: IHttpRequestOptions = {
            defaultApiFailureBehavior: false
        };

        return this.httpService
        .postLower<IOfferingCardsProcessResult>(`${this.baseApi}/offeringCardsProcess`,
        {
            index: this.selectedCard.index,
            shortCardNumber: this.selectedCard.shortCardNumber,
            childPrivateName: this.regForm.nickname,
            childCellPhone: this.regForm.phone,
            privateNameEng: this.privateNameEng,
            familyNameEng: this.familyNameEng
        }, options)
        .pipe(
            tap((x) => {
                this.showLoader.emit({loader: false});
                if (x.returnCode !== 0) {
                    this.navigateToErrorPage('offeringCardsProcess', x.returnCode);
                }
            })
        );
    }

    navigateToErrorPage(serviceName: string, returnCode: number, childPages: boolean = false): void {
        let err = this.errorPagesContent.errorPages.find(
            (e: IErrorPage) => e.errorReturnCode === returnCode.toString() && e.service === serviceName); // Page specific error
        if ( err === undefined ) {
            err = this.errorPagesContent.errorPages.find(
                (e: IErrorPage) => e.errorReturnCode === returnCode.toString() && e.service === 'General'); // General RC specific error
        }
        if ( err === undefined ) {
            err = this.errorPagesContent.errorPages.find(
                (e: IErrorPage) => e.errorReturnCode === '9' && e.service === 'General'); // General RC 9 error
        }
        if ( err === undefined ) {
            this.router.navigate(['errornew']); // General non umbraco based error
            return;
        }
        this.error = err;
        if (err.isPopup) {
            this.openDialog.emit(serviceName);
        } else {
            this.nextPage = 'error-page';
            if (childPages) {
                const field = this.activeRoute.snapshot.queryParamMap.get('field');
                this.router.navigate(['my-max/child/error-page'], { queryParams: { field: field } });
            } else {
                this.router.navigate(['my-max/personal/error-page']);
            }
        }
    }

    showLeavingPopup(dest: string): void {
        if (!this.isShowLeavingPopup) {
            this.error = {
                buttonText: 'חזרה לתהליך ההצטרפות',
                buttonUrlPath: 'close-popup',
                image: {url: '/assets/images/my-max/awesome-owl.svg', alt: 'awesome-owl'},
                errorReturnCode: "0",
                isPopup: true,
                linkUrlPath: '',
                service: 'leavingPopup',
                title: 'אנחנו שמחים שהתעניינת ב-MyMAX',
                text: `ועצובים שבחרת לא להמשיך #LINEBREAK
                טעות או התחרטת? פשוט ללחוץ על "חזרה לתהליך ההצטרפות"`,
                linkText: 'יציאה מתהליך ההצטרפות'
            };
            this.openDialog.emit('leavingPopup');
            // const sub: Subscription = (this.modalService.openModal(iioLeavingPopup).subscribe(res => {
            //     this.isShowLeavingPopup = false;
            //     if (res === 1) {
            //         // Button Pressed
            //     } else if (res === 2) {
            //         this.nextPage = dest.split('/')[-1];
            //         this.isPopupNavigation = true;
            //         this.router.navigate([dest]);
            //     }
            //     sub.unsubscribe();
            // }));
        }
    }

    getChildCardDetails(enableErrorPage: boolean = false): Observable<IResponseLower<ImyMaxCard[]>> {
        this.showLoader.emit({loader: true, blank: true});
            return this.httpService.postLower<ImyMaxCard[]>(`${this.baseApi}/GetChildCardDetails`, null).pipe(
                tap((x) => {
                    if (x.returnCode === 0) {
                        this.cardDetailsRes = x.result;
                    } else if (enableErrorPage) {
                        this.nextPage = 'error-page';
                        this.router.navigate(['my-max/personal/error-page']);
                    }
                    this.showLoader.emit({loader: false, blank: false});
                })
            );
    }

    getDashboardContent(): Observable<IResponseLower<IDashboardContent>> {
        const options: IHttpRequestOptions = {
            defaultApiFailureBehavior: false
        };
            return this.httpService.getLower<IDashboardContent>(`${this.baseApi}/getDashboardContent`, null, options);
    }

    getDigitalForms(): Observable<IResponseLower<IGetDigitalFormsResult>> {
        this.showLoader.emit({loader: true});
        return this.httpService.postLower<IGetDigitalFormsResult>(`${this.baseApi}/getDigitalForms`, null).pipe(
            tap((x) => {
                this.showLoader.emit({loader: false});
                if (x.returnCode !== 0) {
                    this.navigateToErrorPage('getDigitalForms', x.returnCode);
                }
            })
        );
    }

    checkDigitalForms(): Observable<IResponseLower<any>> {
        this.showLoader.emit({loader: true});
        return this.httpService.postLower<any>(`${this.baseApi}/checkDigitalForms`, null).pipe(
            tap((x) => {
                this.showLoader.emit({loader: false});
                if (x.returnCode !== 0) {
                    this.navigateToErrorPage('checkDigitalForms', x.returnCode);
                }
            })
        );
    }

    getCardActionsContent(showBlank?: boolean): Observable<IResponseLower<ICardActionsContent>> {
            this.showLoader.emit({loader: true, blank: showBlank ? true : undefined});
            return this.httpService.getLower<ICardActionsContent>(`${this.baseApi}/getCardActionsContent`, null).pipe(
                tap((x) => {
                    this.cardActionsContent = x.result;
                    this.showLoader.emit({loader: false, blank: showBlank ? false : undefined});
                })
            );
    }
    calculationMaxMinChargingForChild(mymaxCardIndex: number): Observable<IResponseLower<ICalculationMaxMinCharging>> {
            this.showLoader.emit({loader: true});
            return this.httpService.postLower<ICalculationMaxMinCharging>
            (`${this.baseApi}/calculationMaxMinChargingForChild`, {index: mymaxCardIndex}).pipe(
                tap((x: IResponseLower<ICalculationMaxMinCharging>) => {
                    x.result.maxFamilyCharging = Math.floor(x.result.maxFamilyCharging);
                    x.result.minCharging = Math.ceil(x.result.minCharging);
                    this.showLoader.emit({loader: false});
                    if (x.returnCode !== 0) {
                        this.navigateToErrorPage('calculationMaxMinChargingForChild', x.returnCode);
                    } else if (x.result.maxFamilyCharging < 50) {
                        this.navigateToErrorPage('calculationMaxMinChargingForChild', 50);
                    }
                    this.MaxMinChargingForChildData = x.result;
                })
            );
    }
    getChargingEligibilityForExistingCard(mymaxCardIndex: number): Observable<IResponseLower<any>> {
        this.showLoader.emit({loader: true});
        return this.httpService.postLower<any>(`${this.baseApi}/getChargingEligibilityForExistingCard`, {mymaxCardIndex}).pipe(
            tap((x) => {
                this.showLoader.emit({loader: false});
                if (x.returnCode !== 0) {
                    this.navigateToErrorPage('getChargingEligibilityForExistingCard', x.returnCode);
                }
            })
        );
    }
    getDischargingEligibility(mymaxCardIndex: number): Observable<IResponseLower<any>> {
        this.showLoader.emit({loader: true, blank: true});
        return this.httpService.postLower<any>(`${this.baseApi}/getDischargingEligibility`, {mymaxCardIndex}).pipe(
            tap((x) => {
                this.showLoader.emit({loader: false, blank: false});
                if (x.returnCode !== 0) {
                    this.navigateToErrorPage('getDischargingEligibility', x.returnCode);
                }
            })
        );
    }

    chargingForChildCard(req: IChargingForChildCardRequest): Observable<IResponseLower<any>> {
        this.permenantDepositReq = {
            amount: req.Amount,
            day: req.EffectiveDay,
            transType: req.TransType
        };
        this.showLoader.emit({loader: true});
            return this.httpService.postLower<any>(`${this.baseApi}/chargingForChildCard`, req).pipe(
                tap((x) => {
                    this.showLoader.emit({loader: false});
                    if (x.returnCode !== 0) {
                        if (this.chargingNewCard) {
                            if (req.TransType === TransType.OneTimeCharge) {
                                this.SingleChargedSum = -1; // Indication for a failure in the single deposit proccess
                            } else if (req.TransType === TransType.MonthlyCharge) {
                                this.monthlyChargedSum = -1; // Indication for a failure in the permanent deposit proccess
                            }
                            this.nextPage = 'success-page';
                            this.router.navigate(['my-max/personal/success-page']);
                        } else {
                            this.navigateToErrorPage('chargingForChildCard', x.returnCode);
                        }
                    } else {
                        if (req.TransType === TransType.MonthlyCharge) {
                            this.monthlyChargedSum = req.Amount;
                        } else if (req.TransType === TransType.OneTimeCharge) {
                            this.SingleChargedSum = req.Amount;
                        }
                }
                })
            );
    }
    navigateTo(dest: string, activeRoute: ActivatedRoute): void {
        const nav = dest.split('/');
        this.nextPage = nav[nav.length - 1];
        this.router.navigate([dest], { relativeTo: activeRoute });
    }
    passSubsToService(subs: Subscription[]): void {
        this.subs = this.subs.concat(subs);
    }
    unsubscribe(): void {
        this.subs.forEach((s) => s.unsubscribe());
        this.subs = [];
    }

    checkMobileForChild(mobilePhoneNumber: string): Observable<IResponseLower<any>>  {
        this.showLoader.emit({loader: true});
        return this.httpService.postLower<any>(`${this.baseApi}/checkMobileForChild`, {mobilePhoneNumber}).pipe(
            tap((x) => {
                this.showLoader.emit({loader: false});
                if (x.returnCode !== 0) {
                    this.navigateToErrorPage('checkMobileForChild', x.returnCode, true);
                }
            })
        );
    }

    getChildObContent(): Observable<IResponseLower<IChildOnboardingContent>> {
        this.showLoader.emit({loader: true, blank: true});
        return this.httpService.getLower<IChildOnboardingContent>(`${this.baseApi}/GetChildOnboardingContent`).pipe(
            tap((x) => {
                this.showLoader.emit({loader: false, blank: false});
                if (x.result) {
                    this.childObContent = x.result;
                }
            })
        );
    }

    getEncryptedPhoneNumber(mobilePhoneNumber: string): Observable<IResponseLower<string>> {
        this.showLoader.emit({loader: true});
        return this.httpService.postLower<any>(`${this.baseApi}/getEncryptedPhoneNumber`, {phoneNumber: mobilePhoneNumber}).pipe(
            tap((x) => {
                this.showLoader.emit({loader: false});
            })
        );
    }
}
