export interface IForeignCurrencyPockets {
    PocketsList: IPocket[];
    Summary: IPocketsSummary;
    CanOpenNewPocket: boolean;
}

export interface IPocketsSummary {
    CardsAmount: number;
    Currencies: number[];
    PocketsAmount: number;
    EmptyPocketsAmount: number;
}


export interface IPocket {
    CardInfo: ICardInfo;
    Currencies: ICurrency[];
    Action: ActionLink;
}

export interface ICurrency {
    Balance: number;
    CurrencyId: number;
    IsActive: boolean;
    MpCurrency: number;
}

export interface ICardInfo {
    CardImage: string;
    Last4Digits: string;
    CardIndex: number;
}

export enum ActionLink {
    ActiveAccount = 1,
    Info = 2,
    Charge = 3
}