import { ILcImage } from 'ClientApp/app/shared/models/Image';

export interface IHomePageRecommendationsDataResult {
    HomePageRecommendationsResults: IHomePageRecommendationsResult[];
    BackgroundRecResult: IComponentBackgroundRecData;
}

export interface IComponentBackgroundRecData {
   BackgroundFirstCardRec : ComponentBackground;
   BackgroundFirstLoanRec : ComponentBackground;
   BackgroundFirstPointRec: ComponentBackground;
   BackgroundSecondCardRec: ComponentBackground;
   BackgroundSecondLoanRec: ComponentBackground;
   BackgroundSecondPointRec: ComponentBackground;
}
export interface ComponentBackground {
    BackgroundColor: string;
    BackgroundColorOpacity: number;
    BackgroundType: string;
    DesktopBackgroundImage: ILcImage;
    MobileBackgroundImage: ILcImage;
    BackgroundGradientColor : string;
}

export interface IHomePageRecommendationsResult {
    RecommendationId: string;
    ExternalRecommendationId: string;
    RecommendationName: string;
    RecommendationCategory: RecommendationCategory;
    RecommendationLink: string;
    RecommendationLinkTitle: string;
    RecommendationTitle: string;
    RecommendationLinkOpenNewTab: boolean;
    RecommendationSubTitle: string;
    RecommendationColor: string;
    RecommendationBackgroundPicDesktop: ILcImage;
    RecommendationBackgroundPicMobile: ILcImage;
    RecommendationSpecialType: string;
    GeneralText: string;
    FontColor: string;
    RecommendationSubTitleForLottie: string;
    RecommendationLottie: string;
    RecommendationIcon: string;
}

export enum RecommendationCategory {
    Points,
    Loans,
    Debits,
    ProductsForYou
}
