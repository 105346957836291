<button
    (click)="close()"
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    *ngIf="!loginWithMaxMode && !loginPageMode"
>
    <span aria-hidden="true">&times;</span>
</button>

<div *ngIf="!isLoginFailedWithTechnicalError && !(isMobile && loginWithMaxMode)" class="modal-header" [ngClass]="{'beyachad': isFromBeyachad}">
    <h3 aria-level="2" *ngIf="!isFromBeyachad">כיף לראות אותך</h3>
</div>

<div
    *ngIf="!isLoginFailedWithTechnicalError"
    class="modal-body"
    [ngClass]="{ isFingerPrint,disableAppleId: step === 2 && tabs=='otp' }"
>
    <div *appLoader="hideLoader; class: 'login'">
        <div
            *ngIf="!isLoginFailed"
            class="login-tabs"
            [ngClass]="{
                'otp-step2': step === 2 || loginWithMaxMode,
                'less-padding': isExtraAuthRequired,
                showAppleSignIn: showAppleSignIn
            }"
        >
            <!-- Nav tabs -->
            <!--<ul class="nav nav-tabs" role="tablist">
            <li role="presentation" class="active"><a href="#login-id" aria-controls="login-id" role="tab" data-toggle="tab">כניסה עם תעודת זהות</a></li>
            <li role="presentation"><a href="#login-password" aria-controls="login-password" role="tab" data-toggle="tab">כניסה עם סיסמה</a></li>
        </ul>-->
            <!-- Tab panes -->
            <!-- add class enter-code on step2  in enter with id tab  -->
            <tabset #loginFormTabs>
                <tab id="login-id" heading="כניסה עם תעודת זהות" (selectTab)="onOtpTabClicked()">
                    <app-otp-login-form
                        [dontShowEmptyFieldError]="dontShowEmptyFieldError"
                        (loginFailed)="onLoginFailed($event)"
                        [userIdFromUserLoginForm]="passUserIdToOTPForm"
                        (loginFailedWithTechnicalError)="onLoginFailedWithTechnicalError($event)"
                        (switchLoginTab)="switchTab($event)"
                        (updateLoaderStatusEvent)="updateLoaderStatus($event)"
                        (stepChanged)="onOtpStepChange($event)"
                        [otpTabClicked]="otpTabClicked.asObservable()"
                        [sendOtpId]="sendOtpId"
                    ></app-otp-login-form>
                </tab>
                <tab
                    id="login-password"
                    [ngClass]="{ 'login-password-sky-max': loginWithMaxMode }"
                    heading="כניסה עם סיסמה"
                    (selectTab)="onPasswordTabClicked()"
                >
                    <app-user-login-form
                        [dontShowEmptyFieldError]="dontShowEmptyFieldError"
                        (loginFailed)="onLoginFailed($event)"
                        (isFingerPrintResult)="isFingerPrintResult($event)"
                        (needIdChanged)="onNeedIdChanged()"
                        (userIdForOTP)="passUserIdToOTPTab($event)"
                        (loginFailedWithTechnicalError)="onLoginFailedWithTechnicalError($event)"
                        (updateLoaderStatusEvent)="updateLoaderStatus($event)"
                        [clearForm]="otpCodeSent.asObservable()"
                        [passwordTabClicked]="passwordTabClicked.asObservable()"
                    ></app-user-login-form>
                </tab>
            </tabset>
        </div>

        <div *ngIf="showAppleSignIn && !isLoginFailed" class="apple-sign-in-wrapper">
            <app-sign-in-with-apple
                [dontShowEmptyFieldError]="dontShowEmptyFieldError"
                (updateLoaderStatusEvent)="updateLoaderStatus($event)"
                (loginFailed)="onLoginFailed($event)"
                (needIdChanged)="onNeedIdChanged()"
            ></app-sign-in-with-apple>
        </div>

        <div *ngIf="showGoogleFingerPrint">
            <app-google-finger-print
                [activateFp]="activateFp.asObservable()"
                (updateLoaderStatusEvent)="updateLoaderStatus($event)"
                (loginFailed)="onLoginFailed($event)"
                (needIdChanged)="onNeedIdChanged()"
            ></app-google-finger-print>
        </div>

        <div *ngIf="showFido2">
            <app-fido2
                [username]="fido2Username"
                (updateLoaderStatusEvent)="updateLoaderStatus($event)"
                (loginFailed)="onLoginFailed($event)"
                (needIdChanged)="onNeedIdChanged()"></app-fido2>
        </div>

        <div *ngIf="isLoginFailed" class="block-login">
            <app-error-screen
                [params]="errorScreenParams"
                (onButtonForTryAgainClick)="isLoginFailed = false"
            ></app-error-screen>
        </div>
        <div class="loader-wrapper">
            <div class="loader">
                <div class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <span class="text">{{ loadingText }}</span>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isLoginFailedWithTechnicalError" class="no-id-now">
    <span class="no-id"></span>
    <div class="text">
        <span class="title">אי אפשר להזדהות כרגע.</span>
        <span>כדאי לנסות להתחבר שוב מאוחר יותר. </span>
    </div>
</div>
<div *ngIf="!isLoginFailedWithTechnicalError && !isLoginFailed"
    class="banner" (click)="clickBanner()" u1-link>
    <img src="/assets/images/login/banner-app.svg" alt="אפליקציית max להורדה. חוסכים זמן עם זיהוי ביומטרי"/>
</div>
